import React, { useContext } from "react";
import { Box, Text, Image, Heading } from "@chakra-ui/react";
import "./theme5.css";
import ResumeContext from "../../Context/ResumeContext";

const Theme5 = (props) => {
  const { componentRef, themeData } = props;
  const { name, address, phone, email, profile, profileImage, summary, skill } =
    themeData.personalData;

  const { checkProj, checkWork, checkAward } = useContext(ResumeContext);
  const { projectTitles, projectDesc } = themeData.projectData;
  const { educationTitles, educationDesc } = themeData.educationData;
  const { workTitles, workDesc } = themeData.workData;
  const { awards } = themeData.awardData;
  return (
    <Box id="section-to-print" ref={componentRef}>
      <Box id="theme5">
        <div className="d-flex ">
          <header
            id="header"
            className="text-start ml-5 justify-content-between align-items-center"
          >
            <Box className="mb-5 ">
              <Box className="mt-2 p-2 mb-5">
                <Image
                  id="resume-avatar"
                  borderRadius={100}
                  boxSize={"150px"}
                  src={profileImage}
                  alt="Profile Picture"
                />
              </Box>
              <Heading as="h2" size="small" id="name" className="mb-4 m-3">
                {name}
              </Heading>
              <Text
                fontWeight={"500"}
                fontSize="medium"
                className="mt-1 m-3 mb-5"
              >
                {profile}
              </Text>
              <Box className="m-2">
                <Heading fontSize="xl" className="my-2">
                  Contact
                </Heading>
                <Box className="mt-3">
                  <Heading fontSize="md" className="my-2">
                    Phone
                  </Heading>
                  <Text fontSize={"sm"}>{phone}</Text>
                  <Heading fontSize="md" className="my-2">
                    Email
                  </Heading>
                  <Text fontSize={"xs"}>{email}</Text>
                  <Heading fontSize="md" className="my-2">
                    Address
                  </Heading>
                  <Text width={"190px"} id="adress" fontSize={"sm"}>
                    {address}
                  </Text>
                </Box>
              </Box>
              <Box className="mt-5 m-3">
                <Heading fontSize="2xl" className="my-2">
                  Skills
                </Heading>
                <Box className="mt-3">
                  {skill.split(",").map((item, index) => {
                    return (
                      <ul key={index}>
                        <li className="mx-3" key={index}>
                          {item}
                        </li>
                      </ul>
                    );
                  })}
                </Box>
              </Box>
            </Box>
          </header>
          <section
            id="info-right"
            className="text-start ml-5 mt-3 justify-content-between align-items-center"
          >
            <Box className="mb-5 p-3 ">
              <Box id="summery-text" width={"500px"}>
                <Text fontSize="sm" className="mt-1 mb-2 ">
                  {summary}
                </Text>
              </Box>
              {!checkWork && (
                <Box id="experience-area" width={"500px"}>
                  <Heading fontSize="2xl" className="mt-4 work-exp">
                    Work Experience
                  </Heading>
                  <div className="border border-dark "></div>
                  {Object.entries(workTitles).map((element, index) => {
                    return (
                      <Box key={index} className="mt-1">
                        <Heading fontSize="md" className="my-2">
                          {element[1]}
                        </Heading>
                        <Box className="sub-details">
                          {Object.entries(workDesc)[index] === undefined
                            ? null
                            : Object.entries(workDesc)
                                [index][1].split(",")
                                .map((element, index) => {
                                  return <li key={index}>{element}</li>;
                                })}
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              )}
              {!checkProj && (
                <Box id="project-area" width={"500px"}>
                  <Heading fontSize="2xl" className="mt-4">
                    Projects
                  </Heading>
                  <div className="border border-dark "></div>
                  {Object.entries(projectTitles).map((element, index) => {
                    return (
                      <Box key={index} className="mt-1">
                        <Heading fontSize="md" className="my-2">
                          {element[1]}
                        </Heading>
                        <Box className="sub-details">
                          {Object.entries(projectDesc)[index] === undefined
                            ? null
                            : Object.entries(projectDesc)
                                [index][1].split(",")
                                .map((element, index) => {
                                  return <li key={index}>{element}</li>;
                                })}
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              )}
              <Box id="education-area" width={"500px"}>
                <Heading fontSize="2xl" className="my-2">
                  Education
                </Heading>
                <div className="border border-dark "></div>
                {Object.entries(educationTitles).map((element, index) => {
                  return (
                    <Box key={index} className="mt-3">
                      <Heading fontSize="md" className="my-2">
                        {element[1]}
                      </Heading>
                      <Box className="sub-details">
                        {Object.entries(educationDesc)[index] === undefined
                          ? null
                          : Object.entries(educationDesc)
                              [index][1].split(",")
                              .map((element, index) => {
                                return (
                                  <li key={index} width={"10"}>
                                    {element}
                                  </li>
                                );
                              })}
                      </Box>
                    </Box>
                  );
                })}
              </Box>
              {!checkAward && (
                <Box id="award-area" width={"500px"}>
                  <Heading fontSize="2xl" className="mt-4">
                    Awards & Achievement
                  </Heading>
                  <div className="border border-dark mt-1"></div>
                  <Box className="mt-1">
                    {awards.split(",").map((element, index) => {
                      return <li key={index}>{element}</li>;
                    })}
                  </Box>
                </Box>
              )}
            </Box>
          </section>
        </div>
      </Box>
    </Box>
  );
};

export default Theme5;
