import React, { useContext } from "react";
import { Box, Text, Image, Heading, Link, Badge } from "@chakra-ui/react";
import ResumeContext from "../../Context/ResumeContext";
import "./theme4.css";

const Theme4 = (props) => {
  const { componentRef, themeData } = props;
  const {
    name,
    address,
    phone,
    email,
    profile,
    profileImage,
    summary,
    skill,
    Links,
  } = themeData.personalData;
  const { checkWork } = useContext(ResumeContext);

  const { educationTitles, educationDesc } = themeData.educationData;
  const { workTitles, workDesc } = themeData.workData;

  const linksArray = Links.split(",").map((link) => link.trim());
  return (
    <Box id="section-to-print" ref={componentRef}>
      <Box id="theme4">
        <div className="main-page">
          <header className="header">
            <Box className="image-box">
              <Image
                id="resume-avatares"
                borderRadius={100}
                boxSize={"150px"}
                src={profileImage}
                alt="Profile Picture"
              />
            </Box>
            <Box className="prsl-inf">
              <Heading className="name-tag">{name}</Heading>
              <Text className="profile-tag">{profile}</Text>
              <Heading className="about-tag">ABOUT ME</Heading>
              <Text className="summary">{summary}</Text>
              <Box className="links-box">
                {linksArray.map((link, index) => (
                  <Text
                    key={index}
                    href={link}
                    isExternal
                    color="blue.500"
                    className="link"
                  >
                    {link}
                  </Text>
                ))}
              </Box>
              <Box className="contact-box">
                <Heading className="contact-heading">Contact</Heading>
                <Box className="contact-details">
                  <Heading className="contact-title">Phone</Heading>
                  <Text>{phone}</Text>
                  <Heading className="contact-title">Email</Heading>
                  <Text>{email}</Text>
                  <Heading className="contact-title">Address</Heading>
                  <Text>{address}</Text>
                </Box>
              </Box>
              <Box className="skills-box">
                <Heading className="skills-heading">Skills</Heading>
                <Box className="skills-details">
                  {skill.split(",").map((item, index) => {
                    return (
                      <div key={index}>
                        <Badge className="skill-badge" key={index}>
                          {item}
                        </Badge>
                        <br />
                      </div>
                    );
                  })}
                </Box>
              </Box>
            </Box>
          </header>
          <section className="content">
            <Box id="education-area">
              <Heading className="section-heading">Education</Heading>
              {Object.entries(educationTitles).map((element, index) => {
                return (
                  <Box key={index} className="section-item">
                    <Heading className="item-title">{element[1]}</Heading>
                    <Box className="sub-details">
                      {Object.entries(educationDesc)[index] === undefined
                        ? null
                        : Object.entries(educationDesc)
                            [index][1].split(",")
                            .map((element, index) => {
                              return (
                                <li key={index} className="list-item">
                                  {element}
                                </li>
                              );
                            })}
                    </Box>
                  </Box>
                );
              })}
            </Box>
            {!checkWork && (
              <Box id="experience-area">
                <Heading className="section-heading">Work Experience</Heading>
                {Object.entries(workTitles).map((element, index) => {
                  return (
                    <Box key={index} className="section-item">
                      <Heading className="item-title">{element[1]}</Heading>
                      <Box className="sub-details">
                        {Object.entries(workDesc)[index] === undefined
                          ? null
                          : Object.entries(workDesc)
                              [index][1].split(",")
                              .map((element, index) => {
                                return (
                                  <li key={index} className="list-item">
                                    {element}
                                  </li>
                                );
                              })}
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            )}
          </section>
        </div>
      </Box>
    </Box>
  );
};

export default Theme4;
